import {AppDataService} from './../../app-data.service';
import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {StatusHelperService} from '../../services/helpers/status-helper.service';
import {MembersService} from '../../services/members.service';
import {ConfigurationService} from '../../services/configuration.service';
import {ToastrService} from 'ngx-toastr';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {SecurityService} from '../../services/security/security.service';
import {PartyExtensionService} from '../../services/party-extension.service';
import {UserPreferencesService} from './../../services/user-preferences.service';
import {InstrumentsService} from './../../services/instruments.service';
import {UsersService} from 'src/app/services/users.service';
import {Person} from 'src/app/data/model';
import {Subscription} from 'rxjs';
import {GlobalEventService} from 'src/app/services/global-event.service';
import {TabSwapEvent, TabControllerService, TabTypes} from "../../services/tab-controller.service";

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit, OnDestroy {

  users = false;
  type = 'member';
  activeTabIdx = 0;
  tabsShrinked = false;
  statusHelper: object;
  selectedPerson = new Person();
  selectedPersonId: number;
  allowEmulate: boolean;
  memberUrl: string;
  faPlus = faPlus;
  activeId: number;
  allowedToEmulateMember: boolean;
  hasAccessToSensitiveAccounts: boolean;
  isAdmin: boolean;
  subscription: Subscription;
  tabSubscription: Subscription;
  primaryInstrumentNumber: string;
  showMemberPanel = false;
  stretch = false;

  tabs = [
    {
      ifPredicate: () => !this.users,
      displayName: 'Ways to Earn', title: 'Promotions',
      idx: 0
    },
    {
      ifPredicate: () => !this.users,
      displayName: 'Activity History', title: 'activities',
      idx: 1
    },
    {
      ifPredicate: () => !this.users,
      displayName: 'Accounts & Rewards', title: 'Accounts',
      idx: 2
    },
    {
      ifPredicate: () => this.appDataService.isCSRManager && !this.users,
      displayName: 'Instruments', title: 'Instruments',
      idx: 3
    },
    {
      ifPredicate: () => (((this.appDataService.isCSRManager || this.securityService.allowFullMemberFunctionality) && this.allowedToEmulateMember) || (this.hasAccessToSensitiveAccounts)),
      displayName: 'Contacts', title: 'Contacts',
      idx: 4
    },
    {
      ifPredicate: () => true,
      displayName: 'Attributes', title: 'Attributes',
      idx: 5
    },
    {
      ifPredicate: () => this.appDataService.isCSRManager,
      displayName: 'Segments', title: 'Segments',
      idx: 6
    },
    {
      ifPredicate: () => this.users,
      displayName: 'Access roles', title: 'Access Roles',
      idx: 7
    },
    {
      ifPredicate: () => ((this.appDataService.isCSR && !this.appDataService.isPartner) || this.appDataService.isCSRManager) && !this.users,
      displayName: 'Notes', title: 'Notes',
      idx: 8
    },
    {
      ifPredicate: () => (this.appDataService.isAdmin || this.securityService.allowSurveysTab) &&!this.users,
      displayName: 'Survey', title: 'Survey',
      idx: 9
    },
    {
      ifPredicate: () => (this.appDataService.isAdmin || this.securityService.allowDocumentsTab) && !this.users,
      displayName: 'Documents', title: 'Documents',
      idx: 10
    },

    {
      ifPredicate: () => ((this.appDataService.isCSR && !this.appDataService.isPartner) || this.appDataService.isCSRManager) && !this.users,
      displayName: 'Communications', title: 'Communications',
      idx: 11
    },
    {
      ifPredicate: () => ((this.appDataService.isCSR && !this.appDataService.isPartner  && this.securityService.allowEventsAndMeasuresTabs) || this.appDataService.isCSRManager && this.securityService.allowEventsAndMeasuresTabs) && !this.users,
      displayName: 'Events', title: 'Events',
      idx: 12
    },
    {
      ifPredicate: () => ((this.appDataService.isCSR && !this.appDataService.isPartner && this.securityService.allowEventsAndMeasuresTabs) || this.appDataService.isCSRManager && this.securityService.allowEventsAndMeasuresTabs) && !this.users,
      displayName: 'Measures', title: 'Measures',
      idx: 13
    },
    {
      ifPredicate: () => (this.appDataService.isCSRManager || this.securityService.allowFullMemberFunctionality) && !this.users,
      displayName: 'Relationships', title: 'Relationships',
      idx: 14 },
    {
      ifPredicate: () => (this.appDataService.isCSRManager || this.securityService.allowFullMemberFunctionality) && !this.users,
      displayName: 'SSO Identities', title: 'Identities',
      idx: 15 }
  ];
  private behaviorToLoad: string;
  private activitySearchParams: any;

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private membersService: MembersService,
    private configurationService: ConfigurationService,
    private statusHelperService: StatusHelperService,
    private toastr: ToastrService,
    public appDataService: AppDataService,
    public securityService: SecurityService,
    private partyExtensionService: PartyExtensionService,
    private userPreferencesService: UserPreferencesService,
    private usersService: UsersService,
    private globalEvent: GlobalEventService,
    private instrumentsService: InstrumentsService,
    private tabSwitchingService: TabControllerService,
    private cdref: ChangeDetectorRef
  ) {
    this.subscription = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const urlPaths = val.url.split('/');
        if ((urlPaths[1] === 'users' || urlPaths[1] === 'members') && urlPaths.length === 3 && !Number.isNaN(urlPaths[2])) {
          this.ngOnInit();
        }
      }
    });

    this.tabSubscription = this.activatedRoute.fragment.subscribe((fragment: string) => {
       this.changeTabEventListener({ tabType: TabTypes.member, destination: fragment, params: {}});
    });

    this.tabSwitchingService.onTabSwap({ tabType: TabTypes.member, destination: 'any', params: 'any'}, (event) => this.changeTabEventListener(event));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.tabSubscription.unsubscribe();
  }


  changeTabEventListener(tabChangeEvent: TabSwapEvent): void {
    if (tabChangeEvent.destination) {
      const destinationIndex = this.tabs.findIndex((tab: any) => tab.title.toLowerCase() === tabChangeEvent.destination.toLowerCase());
      if (destinationIndex > -1) {
        this.activeTabIdx = destinationIndex;
        if (tabChangeEvent.tabType === TabTypes.member && tabChangeEvent.destination === 'activities' && tabChangeEvent.params) {
          if (tabChangeEvent.params.behaviorToLoad) {
            this.behaviorToLoad = tabChangeEvent.params.behaviorToLoad;
          }

          if (tabChangeEvent.params.activityKeyword) {
            this.activitySearchParams = tabChangeEvent.params;
            this.cdref.detectChanges();
          }
        }
        this.setScrollPosition();
      }
    }
  }

  setScrollPosition(): void {
    const wrapper = document.getElementById('wrapper');
    wrapper.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  changeTab(tabIndex: number): void {
    this.activeTabIdx = tabIndex;
    this.setScrollPosition();
  }

  ngOnInit(): void {
    this.initActiveTabIndex();

    this.users = this.router.url.includes('users');
    const service = this.users ? this.usersService : this.membersService;

    this.isAdmin = this.appDataService.isAdmin;
    if (this.isAdmin) {
      this.hasAccessToSensitiveAccounts = true;
    } else {
      this.hasAccessToSensitiveAccounts = this.userPreferencesService.service.getPreference('layout.hasSensitiveMemberAccess');
    }
    this.selectedPersonId = +this.activatedRoute.snapshot.params.id;
    this.getAllowEmulate();
    service.getEntity(this.selectedPersonId).subscribe((data: any) => {
      if (data.success) {
        this.selectedPerson = data.entity;
        let breadCrumbsData = {};
        if (this.users){
           breadCrumbsData = { personName: this.selectedPerson.username, id: this.selectedPersonId };
        }else{
          breadCrumbsData = { username: this.selectedPerson.username, id: this.selectedPersonId};
        }
        this.globalEvent.sendGlobalEvent({toComponent: 'bread-crumbs', data: breadCrumbsData});
        this.title.setTitle(this.selectedPerson.firstName + ' ' + this.selectedPerson.lastName);
        this.showMemberPanel = true;
        this.partyExtensionService.getNamedExtension(this.selectedPersonId, { name: 'member_extensions' }).subscribe((data: any) => {
          if (data.success && data.entity && data.entity.member_extensions.allowedToEmulate !== null) {
            this.allowedToEmulateMember = data.entity.member_extensions.allowedToEmulate;
          } else {
            this.allowedToEmulateMember = true;
          }
        }, () => {
          this.toastr.error('Error occured!');
        });
        this.statusHelper = this.statusHelperService.getStatus('badge');
      }
    }, () => {
      this.appDataService.isRequestInProgress = false;
      this.router.navigate(['/forbidden']);
    });
  }

  private initActiveTabIndex() {
    this.users = this.router.url.includes('users');
    this.activeTabIdx = this.tabs.find((tab) => tab.ifPredicate()).idx;
    if (this.users) {
      this.activeTabIdx = this.tabs.find((tab) => tab.title == 'Access Roles' && tab.ifPredicate())?.idx || this.tabs.find((tab) => tab.ifPredicate()).idx
    }
  }

  getAllowEmulate(): void {
    this.securityService.allowEmulate.subscribe((result: boolean) => {
      this.allowEmulate = result;
    });
  }

  toggleTabs(): void {
    this.tabsShrinked = !this.tabsShrinked;
  }

  doEmulate(url: string, tokenResp: any): void {
    const data = {
      token: tokenResp.entity
    };

    $.ajax({
      type: 'POST',
      url: url + '/token',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success(): void {
        window.open(url, '_blank');
      },
      xhrFields: {
        withCredentials: true
      }
    });
  }

  getMemberUrlAndEmulate(tokenResp: any): void {
    // first see if it's defined at the party_extension level
    this.partyExtensionService.getNamedExtension(this.selectedPersonId, 'member_extensions').subscribe((data: any) => {
      if (data.success && data.entity && data.entity.member_extensions && data.entity.member_extensions.memberUrl) {
        this.memberUrl = data.entity.member_extensions.memberUrl;
        this.doEmulate(this.memberUrl, tokenResp);
      } else {
        this.configurationService.getConfiguration('MEMBER_UI_LOCATION').subscribe((configData: any) => {
          if (configData.success && configData.entity && configData.entity.length > 0) {
            this.memberUrl = configData.entity[0].cfgValue;
            this.doEmulate(this.memberUrl, tokenResp);
          } else {
            // otherwise this just isn't configured, so fail it.
            this.toastr.error('Member Impersonation is not configured.');
          }
        });
      }
    });
  }

  impersonateMember(): void {
    this.membersService.getImpersonationToken(this.selectedPersonId).subscribe((tokenResp: any) => {
      if (tokenResp.success && tokenResp.entity) {
        this.getMemberUrlAndEmulate(tokenResp);
      } else {
        this.toastr.error('Member Impersonation failed.');
      }
    });
  }

  tabPredicateRunner(tab: any) {
    return tab.ifPredicate();
  }
}
