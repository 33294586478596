import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IdentitiesService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getIdentities(partyId: number): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'federations/' + partyId
    );
  }
}
