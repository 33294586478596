import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IdentitiesService } from 'src/app/services/identities.service';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons';

interface AMIdentity {
  username: string;
  employeeNumber: string[];
  realm: string;
  givenName: string[];
  sn: string[];
  createTimestamp: string[];
  modifyTimestamp: string[];
}

@Component({
  selector: 'app-member-identities-tab',
  templateUrl: './member-identities-tab.component.html',
  styleUrl: './member-identities-tab.component.scss'
})
export class MemberIdentitiesTabComponent {
    @Input() personId:number;

    identities: AMIdentity[];
    isLoading = false;
    faIdBadge = faIdBadge;

    constructor(
      private IdentitiesService : IdentitiesService,
      private toastr: ToastrService
    ) {}

    ngOnInit(): void {
      this.getIdentities();
    }

    getIdentities(): void {
      this.isLoading = true;
      this.IdentitiesService.getIdentities(this.personId).subscribe(
        (data: AMIdentity[]) => {
          this.identities = data;
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
          this.toastr.error('Error occurred!');
        }
      );
    }
}
