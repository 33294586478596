<div>
  <div class="row">
    <!-- STAGE IMPORT -->
    <div
      [ngClass]="{
        'col-sm-9': enableStandardView,
        'col-sm-12 expanding': !enableStandardView
      }"
      class="expandable-panel"
    >
      <div class="ibox" [@openClose]="enableStandardView ? 'true' : 'false'">
        <div class="ibox-title">
          <div class="row">
            <div class="col-sm-8">
              <h2>Records</h2>
              <div class="form-group">
                <label class="control-label">View:</label>
                <span class="m-l-md">
                  <div class="radio radio-primary radio-inline">
                    <input
                      type="radio"
                      name="rewardStatus"
                      id="rawDataView"
                      value="1"
                      (change)="
                        enableStandardView = !enableStandardView;
                        showFirstImport()
                      "
                      [checked]="!enableStandardView"
                    />
                    <label for="rawDataView"> Raw Data </label>
                  </div>
                  <div class="radio radio-primary radio-inline">
                    <input
                      type="radio"
                      name="rewardStatus"
                      id="standardView"
                      value="2"
                      (change)="
                        enableStandardView = !enableStandardView;
                        showFirstImport()
                      "
                      [checked]="enableStandardView"
                    />
                    <label for="standardView"> Standard </label>
                  </div>
                </span>
              </div>
            </div>
            <div class="col-sm-4"></div>
          </div>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <div>
                <app-search-filter
                  [searchFilters]="searchFilters"
                ></app-search-filter>
                <div class="row table-integrations">
                  <table
                    class="table table-pointable table-bordered infinite-table"
                  >
                    <thead>
                      <tr>
                        <th class="width-8-rem">ID</th>
                        <th class="width-12-rem">Record Status</th>
                        <th *ngIf="enableStandardView">Type</th>
                        <th *ngIf="enableStandardView">Import Date</th>
                        <th *ngIf="enableStandardView">Record Number</th>
                        <th *ngIf="enableStandardView">Created On</th>
                        <th *ngIf="enableStandardView">Validation Message</th>
                        <th *ngIf="enableStandardView && isProgramManager">
                          Action
                        </th>
                        <th *ngIf="!enableStandardView">Raw Data</th>
                      </tr>
                    </thead>
                    <tbody
                      (dblclick)="enableStandardView = !enableStandardView"
                    >
                      <tr
                        *ngFor="let import of imports"
                        (click)="showImport($event, import)"
                      >
                        <td>{{ import.id }}</td>
                        <td>{{ import.status }}</td>
                        <td *ngIf="enableStandardView">
                          {{ import.importType }}
                        </td>
                        <td *ngIf="enableStandardView">
                          {{ import.importDate | date : "yyyy-MM-dd" }}
                        </td>
                        <td *ngIf="enableStandardView">
                          {{ import.recordNumber }}
                        </td>
                        <td *ngIf="enableStandardView">
                          {{ import.createdOn | date : "yyyy-MM-dd" }}
                        </td>
                        <td *ngIf="enableStandardView">
                          {{ import.validationMessage }}
                        </td>
                        <td
                          *ngIf="enableStandardView && isProgramManager"
                          class="white-space-normal animate-fade"
                        >
                          <a
                            [disabled]="import.status === 'RETRY'"
                            class="text-success"
                            (click)="
                              retrySingleImport(import.stageLoadId, import.id)
                            "
                            title="Retry"
                            >Retry</a
                          >
                        </td>
                        <td
                          *ngIf="!enableStandardView"
                          title="{{ import.rawData }}"
                          [innerHTML]="import.rawData"
                        >
                          {{ import.rawData }}
                        </td>
                      </tr>
                      <tr
                        app-is-there-more
                        [numColumns]="enableStandardView ? 8 : 3"
                        [newLengthEvent]="newLengthEvent"
                      ></tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <app-add-more-data-to-list
                (onScroll)="getImports(true)"
                [lock]="lock"
                [isLoading]="isLoading"
              >
              </app-add-more-data-to-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--IMPORT SELECTED-->
    <div
      class="col-sm-3 animate-panel"
      [@slideRight]
      *ngIf="enableStandardView && selectedImport"
      appScrollWithMe
    >
      <div class="ibox">
        <div class="row integration-details-bar"></div>
        <div class="ibox-content">
          <div class="tab-content">
            <div id="contact-1" class="tab-pane active">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3 relative-position">
                      <div class="absolute-position top-20">
                        <span class="fa-stack">
                          <fa-layers [fixedWidth]="true">
                            <fa-icon
                              class="blue-icon-color"
                              [icon]="faCircle"
                              stackItemSize="2x"
                            ></fa-icon>
                            <fa-icon
                              [inverse]="true"
                              [icon]="faCopy"
                              transform="shrink-8"
                              stackItemSize="2x"
                            ></fa-icon>
                          </fa-layers>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-12 form-group panel-container">
                          <span
                            id="status-label"
                            class="badge panel-container"
                            [ngClass]="statusHelper[selectedImport.status]"
                            >{{ selectedImport.status }}</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 pull-left">
                          <h2>{{ selectedImport.id }}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="client-detail">
                <div class="full-height-scroll">
                  <ul class="list-group clear-list">
                    <li class="list-group-item fist-item">
                      Import File Element
                      <span class="panel-container">
                        {{ selectedImport.importFileElement }}</span
                      >
                    </li>
                    <li class="list-group-item">
                      Type
                      <span class="panel-container">
                        {{ selectedImport.importType }}</span
                      >
                    </li>
                    <li class="list-group-item">
                      File Load ID
                      <span class="panel-container">
                        {{ selectedImport.stageLoadId }}</span
                      >
                    </li>
                    <li class="list-group-item">
                      Load Date
                      <span class="panel-container">
                        {{ selectedImport.loadDate }}</span
                      >
                    </li>
                    <li class="list-group-item">
                      Record Number
                      <span class="panel-container">
                        {{ selectedImport.recordNumber }}</span
                      >
                    </li>
                    <li class="list-group-item">
                      Created On
                      <span class="panel-container">
                        {{ selectedImport.createdOn | date : "short" }}</span
                      >
                    </li>
                    <li class="list-group-item">
                      Validation Code
                      <span class="panel-container">
                        {{ selectedImport.validationCode }}</span
                      >
                    </li>
                    <li class="list-group-item">
                      Validation Message
                      <span class="panel-container">
                        {{ selectedImport.validationMessage }}</span
                      >
                    </li>
                  </ul>
                  <strong *ngIf="enableStandardView">Raw Data</strong>
                  <pre *ngIf="enableStandardView">{{
                    selectedImport.rawData
                  }}</pre>
                  <div *ngIf="importMessages && importMessages.length > 0">
                    <strong>Messages</strong>
                    <ul
                      class="list-group clear-list"
                      *ngFor="let msg of importMessages"
                    >
                      <li
                        class="list-group-item fist-item"
                        *ngIf="msg.errorCode"
                      >
                        <span class="panel-container">
                          {{ msg.errorCode }}</span
                        >
                        Status Code
                      </li>
                      <li class="list-group-item fist-item" *ngIf="msg.message">
                        <ngb-alert [dismissible]="false">
                          <strong>Warning!</strong> {{ msg.message }}
                        </ngb-alert>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row integration-details-bar"></div>
      </div>
    </div>
  </div>
</div>
