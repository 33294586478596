import { UserPreferencesService } from './../user-preferences.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppDataService } from './../../app-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '../configuration.service';
import { combineLatest, Observable, Subject, Subscription, zip } from 'rxjs';
import { User } from '../../data/model';
import { GlobalEventService } from '../global-event.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  OAUTH_TOKEN = 'token';
  runAuthGrantForSSOUser: undefined;
  jwth = new JwtHelperService();

  allowEventsAndMeasuresTabs: boolean;
  allowProcessingTab: boolean;
  allowFullMemberFunctionality: boolean;
  allowSurveysTab: boolean;
  allowDocumentsTab: boolean;
  allowReportsTab: boolean;
  allowStatusUpdates: boolean;
  allowReverse: boolean;
  allowAdvancedIntegrationFeatures: boolean;
  allowedToEditAttribs: boolean;
  allowedToAddAttribs: boolean;
  deferredCurrentUser: Subject<User>;
  currentUser: User;
  isSSO: Boolean;
  subscribeVariable: Subscription;
  externalOrgCode: string;
  private urlBase: string = this.globalData.baseUrl + 'v2/auth/oauth/token';
  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService,
    private globalData: AppDataService,
    private userPreferencesService: UserPreferencesService,
    private toastr: ToastrService,
    private globalEventService: GlobalEventService
  ) {
    if (!this.globalData.appConfig) {
      this.globalData.getAppConfig();
    }
  }

  authCodeGrant = new Observable((observer: any) => {
    // clear out the existing token FIRST for two reasons:
    // 1. The oAuthInterceptor sticks it in the auth header and the auth request will fail since
    // the endpoint tries to parse the client_id from the Authorization header
    // 2. We don't want to accidentally leave the token hanging around in case
    // this fails and a new user gets an old user's token (shouldn't share machines but...)
    this.cleanOauth();
    let authCode = localStorage.getItem('authCodeInProgress');
    this.getOauth(authCode).subscribe(
      (response: any) => {
        localStorage.setItem('token', response.accessToken);
        localStorage.setItem('lastGoodCode', authCode);
        localStorage.removeItem('authCodeInProgress');
        this.setCurrentUser.subscribe(observer);
      },
      () => {
        this.setCurrentUser.subscribe(observer);
        localStorage.setItem('lastBadeCode', authCode);
        localStorage.removeItem('authCodeInProgress');
      }
    );
  });

  setCurrentUser = new Observable<User>((observer: any) => {
    const dataToSend: any = {};
    const token = localStorage.getItem('token');

    if (token && !this.isTokenExpired()) {
      const decodedToken = this.jwth.decodeToken(token);
      this.currentUser = new User();
      this.currentUser.username = decodedToken.sub;
      this.currentUser.id = decodedToken.partyId;
      this.currentUser.authorities = decodedToken.roles;

      this.globalData.loggedIn = true;
      this.globalData.token = token;
      this.globalData.restHeaders.Authorization = 'Bearer ' + token;
      this.globalData.userId = decodedToken.partyId;
      this.globalData.username = decodedToken.sub;
      this.globalData.loggedIn = true;
      this.globalData.isCSR = this.globalData.checkPermission('CSR');
      this.globalData.isLiveAgent =
        this.globalData.checkPermission('LIVE_AGENT');
      this.globalData.isLiveAgentManager =
        this.globalData.checkPermission('LIVE_AGENT_MANAGER');
      this.globalData.isPartner = this.globalData.checkPermission('PARTNER');
      this.globalData.isCSRManager =
        this.globalData.checkPermission('CSR_MANAGER');
      this.globalData.isAccountManager =
        this.globalData.checkPermission('ACCOUNT_MANAGER');
      this.globalData.isProgramManager =
        this.globalData.checkPermission('PROGRAM_MANAGER');
      this.globalData.isAdmin = this.globalData.checkPermission('ADMIN');
      this.globalData.requiresPasswordReset =
        decodedToken.requiresPasswordReset;
      this.globalData.username = this.currentUser.username;
      zip(
        this.route.params,
        this.route.queryParams,
        this.initSecurity,
        this.userPreferencesService.service.init,
        this.configurationService.getConfiguration('USE_ALLOWLIST')
      ).subscribe(([params, queryParams, user, prefs, useAllowlist]) => {
        this.externalOrgCode = queryParams.externalOrgCode;
        if (
          this.externalOrgCode !== null &&
          this.externalOrgCode !== undefined
        ) {
          dataToSend.data = { externalOrgCode: this.externalOrgCode };
          dataToSend.toComponent = 'app';
          this.globalEventService.sendGlobalEvent(dataToSend);
        }

        this.globalData.useAllowlist =
          useAllowlist.success &&
          useAllowlist.entity &&
          useAllowlist.entity[0] &&
          useAllowlist.entity[0].cfgValue.toLowerCase() === 'true';

        this.setPreferenceBasedRoleAdjustments();
        this.globalData.appReady = true;
        observer.next(this.currentUser);
        observer.complete();
        localStorage.removeItem('authCodeInProgress');
      });
    } else {
      this.handleLoginError();
      observer.error('No logged in user found');
      observer.complete();
    }
  });

  initSecurity = new Observable<any>((observer: any) => {
    // let other stuff use this observable
    this.globalData.promotionPodType = this.getPromotionView;
    this.getAllowSurveyExport.subscribe(
      (value: any) => {
        this.globalData.allowSurveyExport = value;
        if (this.globalData.isAdmin) {
          this.globalData.allowSurveyExport = true;
        }
        observer.next();
        observer.complete();
      },
      () => {
        this.globalData.allowSurveyExport = false;
        observer.next();
        observer.complete();
      }
    );
  });

  isTokenExpired = (): boolean => {
    let token = localStorage.getItem(this.OAUTH_TOKEN);
    return this.jwth.isTokenExpired(token);
  };

  isLoggedIn = new Observable<boolean>((observer: any) => {
    this.requestCurrentUser.subscribe((user: User) =>
      observer.next(user !== undefined && user !== null)
    );
  });

  requestCurrentUser = new Observable<User>((observer: any) => {
    this.checkIsSSOInstance.then((isSSO) => {
      if (isSSO && this.router.url !== '/login') {
        let authCode = null;
        combineLatest([this.route.params, this.route.queryParams]).subscribe(
          ([params, queryParams]) => {
            if (queryParams.iPlanetDirectoryPro) {
              authCode = queryParams.iPlanetDirectoryPro;
            } else {
              authCode = this.getCookie('iPlanetDirectoryPro');
            }

            let createDeferred = false;
            let lastGoodCode = localStorage.getItem('lastGoodCode');
            let lastBadCode = localStorage.getItem('lastBadCode');
            let notAtLogin = this.router.url.indexOf('login') === -1;
            let authCodeInProgress = localStorage.getItem('authCodeInProgress');

            // a new authCode should trigger a new SSO auth_code grant
            let isNewAuthCode =
              authCode &&
              authCode !== lastGoodCode &&
              authCode !== lastBadCode &&
              authCode !== authCodeInProgress;

            // If not outstanding request, go ahead and create one. if there's an outstanding request for the user,
            // then we should only replace it if we have new authCode OR if it has already been resolved.
            // If there's no outstanding request, or if there's a new auth code, or if the outstanding request has been resolved
            if (
              !this.deferredCurrentUser ||
              isNewAuthCode ||
              this.deferredCurrentUser.isStopped
            ) {
              this.deferredCurrentUser = new Subject<User>();
              localStorage.setItem('authCodeInProgress', authCode);
            } else {
              // If there's an outstanding request, return its results when they're finished
              return this.deferredCurrentUser.asObservable();
            }

            // conditions that indicate we should run the auth_code grant again
            let runAuthGrantForSSOUser = notAtLogin && isNewAuthCode;

            if (runAuthGrantForSSOUser) {
              // we have a SSO user with a NEW authCode so run the authCodeGrant
              // use deferedCurrentUser to return the results of the authCodeGrant
              this.authCodeGrant.subscribe(
                (user: User) => {
                  this.completeDeferredCurrentUser(observer, user);
                },
                (error) => {
                  this.completeDeferredCurrentUser(observer, error);
                }
              );
            } else {
              // No information exists to run the grant, so they must be logged out
              // or already logged in - try to set the user
              let token = localStorage.getItem(this.OAUTH_TOKEN);
              if (token && this.isTokenExpired()) {
                this.handleLoginError();
              } else {
                if (notAtLogin) {
                  this.setCurrentUser.subscribe(
                    (user: User) => {
                      this.completeDeferredCurrentUser(observer, user);
                    },
                    (error) => {
                      this.completeDeferredCurrentUser(observer, error);
                    }
                  );
                } else {
                  this.completeDeferredCurrentUser(observer, null);
                }
              }
            }
          }
        );
      } else {
        // regular folks are trying to login via the login screen
        this.deferredCurrentUser = new Subject<User>();

        this.setCurrentUser.subscribe(
          (user: User) => {
            this.completeDeferredCurrentUser(observer, user);
          },
          (error) => {
            this.completeDeferredCurrentUser(observer, error);
          }
        );
      }
    });
  });

  // helper function to make the code not look like crap
  completeDeferredCurrentUser = (observer: any, user: User) => {
    observer.next(user);
    observer.complete();
    this.deferredCurrentUser?.next(user);
    this.deferredCurrentUser?.complete();
  };

  handleLoginError = (): void => {
    this.cleanOauth();
    this.globalData.onLogout(false);
    let redirectState =
      this.isSSO || (this.isSSO === undefined && this.router.url !== '/login')
        ? '/logged-out'
        : '/login';

    // // TODO: set last location so we can return the user to that page after login
    if (
      this.globalData.appConfig &&
      this.globalData.appConfig.ssoLogoutRedirectUrl &&
      this.globalData.appConfig.ssoLogoutRedirectUrl !== 'DEFAULT' &&
      this.globalData.appConfig.ssoLogoutRedirectUrl !==
        '${SSO_LOGOUT_REDIRECT_URL}' &&
      this.isSSO
    ) {
      // special redirection for certain clients
      var relayState = encodeURIComponent(window.location.href);

      window.location.replace(
        this.globalData.appConfig.ssoLogoutRedirectUrl +
          '&RelayState=' +
          relayState
      );
    } else {
      this.router.navigate([redirectState]); // redirect to login/logged-out via the state name
    }
  };

  handleExpiredToken = (): Observable<any> => {
    this.cleanOauth();
    this.globalData.onLogout(false);
    return this.isLoggedIn;
  };

  // Is the current user an administrator?
  isAdmin = new Observable((observer: any) => {
    const isAdmin = !!(
      this.currentUser && this.currentUser.authorities.indexOf('ADMIN') >= 0
    );
    observer.next(isAdmin);
  });

  isProgramManager = new Observable((observer: any) => {
    let isProgramManager = !!(
      this.currentUser &&
      this.currentUser.authorities.indexOf('PROGRAM_MANAGER') >= 0
    );
    if (!isProgramManager) {
      this.isAdmin.subscribe((result: boolean) => {
        isProgramManager = result;
      });
    }
    observer.next(isProgramManager);
  });

  isAccountManager = new Observable((observer: any) => {
    let isAccountManager = !!(
      this.currentUser &&
      this.currentUser.authorities.indexOf('ACCOUNT_MANAGER') >= 0
    );
    if (!isAccountManager) {
      this.isProgramManager.subscribe((result: boolean) => {
        isAccountManager = result;
      });
    }
    observer.next(isAccountManager);
  });

  isCSRManager = new Observable((observer: any) => {
    let isCSRManager = !!(
      this.currentUser &&
      this.currentUser.authorities.indexOf('CSR_MANAGER') >= 0
    );
    if (!isCSRManager) {
      this.isAccountManager.subscribe((result: boolean) => {
        isCSRManager = result;
      });
    }
    observer.next(isCSRManager);
  });

  isPartner = new Observable((observer: any) => {
    let isPartner = !!(
      this.currentUser && this.currentUser.authorities.indexOf('PARTNER') >= 0
    );
    if (!isPartner) {
      this.isCSRManager.subscribe((result: boolean) => {
        isPartner = result;
      });
    }
    observer.next(isPartner);
  });

  isCSR = new Observable((observer: any) => {
    let isCSR = !!(
      this.currentUser && this.currentUser.authorities.indexOf('CSR') >= 0
    );
    if (!isCSR) {
      this.isPartner.subscribe((result: boolean) => {
        isCSR = result;
      });
    }
    observer.next(isCSR);
  });

  cleanOauth = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('PartyExtensions');
    localStorage.removeItem('orgId');
    localStorage.removeItem('externalOrgCode');
    localStorage.removeItem('orgUsername');
    localStorage.removeItem('segmentId');
    localStorage.removeItem('organizationLogoUrl');
    this.userPreferencesService.service.handleLogout();
    this.currentUser = undefined;
    this.globalData.username = undefined;
  };

  isAuthenticated = (): boolean => {
    // we should always have a token if we're logged in - having a currentUser set should not be enough
    const token = localStorage.getItem('token');
    return !!token && !!this.currentUser;
  };

  checkIsSSOInstance = new Promise((resolve) => {
    this.globalData.baseUrl + 'configuration/SSO';
    fetch(this.globalData.baseUrl + 'configuration/SSO').then(
      (response: any) => {
        response.json().then((data: any) => {
          this.isSSO = data.success && data.entity && data.entity === 'true';
          if (this.getCookie('qa-sso-flag')) {
            this.isSSO = true;
          }
          resolve(this.isSSO);
        });
      }
    );
  });

  cannotEdit = new Observable((observer: any) => {
    this.configurationService.getConfiguration('CANNOT_EDIT').subscribe(
      (data: any) => {
        observer.next(
          data.success &&
            data.entity &&
            data.entity.length > 0 &&
            data.entity[0].cfgValue === 'true'
        );
        observer.complete();
      },
      () => {
        observer.error('Error getting cannot edit');
      }
    );
  });

  getPromotionView = new Observable<string>((observer: any) => {
    this.configurationService.getConfiguration('ANTHEM_PROMO_VIEW').subscribe(
      (data: any) => {
        if (
          data.success &&
          data.entity &&
          data.entity.length > 0 &&
          data.entity[0].cfgValue === 'true'
        ) {
          observer.next('anthem-commercial');
        } else {
          observer.next('standard');
        }
        observer.complete();
      },
      () => {
        observer.error('Error getting anthem promo view');
      }
    );
  });

  getAllowSurveyExport = new Observable<string>((observer: any) => {
    this.configurationService.getConfiguration('ALLOW_SURVEY_EXPORT').subscribe(
      (data: any) => {
        if (
          data.success &&
          data.entity &&
          data.entity.length > 0 &&
          data.entity[0].cfgValue === 'true'
        ) {
          observer.next(true);
        } else if (this.globalData.isAdmin) {
          observer.next(true);
        } else {
          observer.next(false);
        }
        observer.complete();
      },
      () => {
        observer.error('Error getting allow survey export');
      }
    );
  });

  allowEmulate = new Observable((observer: any) => {
    this.configurationService.getConfiguration('ALLOW_EMULATE').subscribe(
      (data: any) => {
        observer.next(
          data.success &&
            data.entity &&
            data.entity.length > 0 &&
            data.entity[0].cfgValue === 'true'
        );
        observer.complete();
      },
      () => {
        observer.error('Error getting allow emulate');
      }
    );
  });

  disableTemplateEdit = new Observable((observer: any) => {
    this.configurationService
      .getConfiguration('DISABLE_TEMPLATE_EDITS')
      .subscribe(
        (data: any) => {
          observer.next(
            data.success &&
              data.entity &&
              data.entity.length > 0 &&
              data.entity[0].cfgValue === 'true'
          );
          observer.complete();
        },
        () => {
          observer.error('Error getting disable template edits');
        }
      );
  });

  useCSVReports = new Observable((observer: any) => {
    this.configurationService.getConfiguration('CSV_REPORTS').subscribe(
      (data: any) => {
        observer.next(
          data.success &&
            data.entity &&
            data.entity.length > 0 &&
            data.entity[0].cfgValue === 'true'
        );
        observer.complete();
      },
      () => {
        observer.error('Error getting CSV reports');
      }
    );
  });

  private setPreferenceBasedRoleAdjustments() {
    [
      {
        name: 'layout.allowProcessingTabRole',
        yesCallBack: (result: any) => {
          this.allowProcessingTab = result;
        },
        noCallBack: () => {
          this.allowProcessingTab = this.globalData.isCSRManager;
        },
      },
      {
        name: 'layout.allowFullMemberFunctionalityRole',
        yesCallBack: (result: any) => {
          this.allowFullMemberFunctionality = result;
        },
        noCallBack: () => {
          this.allowFullMemberFunctionality = false;
        },
      },
      {
        name: 'layout.allowReportsTabRole',
        yesCallBack: (result: any) => {
          this.allowReportsTab = result;
        },
        noCallBack: () => {
          this.allowReportsTab = false;
        },
      },
      {
        name: 'layout.allowEventsAndMeasuresRole',
        yesCallBack: (result: any) => {
          this.allowEventsAndMeasuresTabs = result;
        },
        noCallBack: () => {
          this.allowEventsAndMeasuresTabs = true;
        },
      },
      {
        name: 'layout.allowStatusUpdatesRole',
        yesCallBack: (result: any) => {
          this.allowStatusUpdates = result;
        },
        noCallBack: () => {
          this.allowStatusUpdates = this.globalData.isCSRManager;
        },
      },
      {
        name: 'layout.allowReverseRole',
        yesCallBack: (result: any) => {
          this.allowReverse = result;
        },
        noCallBack: () => {
          this.allowReverse = true;
        },
      },
      {
        name: 'layout.allowAdvancedIntegrationFeaturesRole',
        yesCallBack: (result: any) => {
          this.allowAdvancedIntegrationFeatures = result;
        },
        noCallBack: () => {
          this.allowAdvancedIntegrationFeatures = this.globalData.isAdmin;
        },
      },
      {
        name: 'layout.allowSurveysTabRole',
        yesCallBack: (result: any) => {
          this.allowSurveysTab = result;
        },
        noCallBack: () => {
          this.allowSurveysTab = this.globalData.isAdmin;
        },
      },
      {
        name: 'layout.allowDocumentsTabRole',
        yesCallBack: (result: any) => {
          this.allowDocumentsTab = result;
        },
        noCallBack: () => {
          this.allowDocumentsTab = this.globalData.isAdmin;
        },
      },
      {
        name: 'layout.allowedToEditAttribsRole',
        yesCallBack: (result: any) => {
          this.allowedToEditAttribs = result;
        },
        noCallBack: () => {
          this.allowedToEditAttribs = this.globalData.isAdmin;
        },
      },
      {
        name: 'layout.allowedToAddAttribsRole',
        yesCallBack: (result: any) => {
          this.allowedToAddAttribs = result;
        },
        noCallBack: () => {
          this.allowedToAddAttribs = this.globalData.isAdmin;
        },
      },
    ]
      .map((element) => ({
        ...element,
        observer:
          this[this.userPreferencesService.service.getPreference(element.name)],
      }))
      .forEach((e: any) => {
        if (e.observer) {
          e.observer.subscribe((result: any) => {
            e.yesCallBack(result);
          });
        } else {
          e.noCallBack();
        }
      });
  }

  private redirect(url: string): void {
    url = url || '/';
    this.router.navigateByUrl(url);
  }

  private processResponse(res: any): any {
    return res.data;
  }

  private processError(e: any): void {
    const msg = [];
    if (e.status) {
      msg.push(e.status);
    }
    if (e.statusText) {
      msg.push(e.statusText);
    }
    if (msg.length === 0) {
      msg.push('Unknown Server Error');
    }

    // TODO: return $q.reject(msg.join(' '));
  }

  private getCookie(name: string): string {
    // const value = `; ${document.cookie}`;
    // const parts = value.split(`; ${name}=`);
    // if (parts.length === 2) return parts.pop().split(';').shift();
    return (
      document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() ||
      ''
    );
  }

  public login(
    username: string,
    password: string,
    callback: (res, boolean) => void,
    errcb: (error: any) => void
  ): void {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded;')
      // .set('Access-Control-Allow-Origin', '*')
      .set('skipInterceptor', 'true');
    const params = new HttpParams()
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password)
      .set('client_id', 'CheetahAdmin')
      .set('client_ip_address', this.globalData.clientIpAddress);
    this.http.post(this.urlBase, params, { headers }).subscribe(
      (res: any) => {
        this.globalData.token = res.accessToken;
        const jwth = new JwtHelperService();
        const dt = jwth.decodeToken(res.accessToken);
        this.globalData.username = dt.sub;
        this.globalData.userId = dt.partyId;
        this.globalData.requiresPasswordReset = dt.requiresPasswordReset;
        this.globalData.restHeaders.Authorization = 'Bearer ' + res.accessToken;
        localStorage.setItem('token', res.accessToken);

        this.setCurrentUser.subscribe(
          (user: User) => {
            callback(user, true);
            this.deferredCurrentUser?.next(user);
            this.deferredCurrentUser?.complete();
          },
          (error) => {
            callback(error, false);
            this.deferredCurrentUser?.error(error);
            this.deferredCurrentUser?.complete();
          }
        );
      },
      () => {
        this.toastr.error(
          'The login information provided is not valid.  Please enter the correct information.'
        );
      }
    );
  }

  // getOauthtoken(authCode: string): any {
  //     return this.http.post(
  //         environment.baseUrl + 'v2/auth/oauth/token',
  //         {
  //             client_id: 'CheetahAdmin',
  //             grant_type: 'authorization_code',
  //             code: authCode,
  //         },
  //         { headers: { skipInterceptor: 'true', 'Content-Type': 'application/x-www-form-urlencoded' } }
  //     );
  // }

  public getOauth(authCode: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded;')
      // .set('Access-Control-Allow-Origin', '*')
      .set('skipInterceptor', 'true');
    const params = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', authCode)
      .set('client_id', 'CheetahAdmin');

    return this.http.post(this.urlBase, params, { headers });
  }

  getConfiguration(configuration: string): any {
    return this.configurationService.getConfiguration(configuration);
  }

  // private setupref(pref: string, yesCallBack: (result) => void, noCallBack: () => void): void {
  //   const observer = this.userPreferencesService.service.getPreference(pref);
  //   if(this[observer] && typeof this[observer].subscribe === 'function'){
  //     observer.subs
  //     yesCallBack();
  //   }else {
  //     noCallBack();
  //   }
  //}

  /*
  private getUserFullName(userId: number): any {
     this.usersService.getUser(userId).subscribe((user: any) => {
        if (user.success) {
          return user.entity.firstName;
        }
    });
  }*/

  public getIpClientAddress(): void {
    this.http
      .get('https://api.ipify.org/?format=json', {
        headers: { skipInterceptor: 'true' },
      })
      .subscribe(
        (res: any) => {
          console.log(res);
          this.globalData.clientIpAddress = res?.ip || '';
        },
        () => {
          // don't show this to the user
          //this.toastr.error('Error getting client IP address');
        }
      );
  }
}
