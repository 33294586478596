<div class="panel-body">
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div>
          <p>
            <span>
              <fa-icon [icon]="faIdBadge"></fa-icon>
            </span>
            This tab shows the OpenAM identities associated with the member that enable single sign-on.  If no identities are found single sign-on will not work for the member.
          </p>
        </div>
        <div *ngIf="identities && identities.length === 0" class="col-md-12">
          <div class="alert alert-info">
            <strong>No identities found.</strong>
          </div>
        </div>
        <div class="col-md-4" *ngFor="let identity of identities">
          <div class="widget lazur-bg p-xl-4">
            <h2>
              <span title="Primary">
                <fa-icon [icon]="faIdBadge"></fa-icon>
              </span>
              OpenAM
            </h2>
            <ul class="list-unstyled mt-3">
              <li>
                <label>Username:</label>
                {{ identity.username }}
              </li>
              <li>
                <label>Employee Number:</label>
                {{ identity.employeeNumber?.join(', ') ?? "n/a" }}
              </li>
              <li>
                <label>Realm:</label>
                {{ identity.realm ?? "n/a" }}
              </li>
              <li>
                <label>Given Name:</label>
                {{ identity.givenName?.join(', ') ?? "n/a" }}
              </li>
              <li>
                <label>SN:</label>
                {{ identity.sn?.join(', ') ?? "n/a" }}
              </li>
              <li>
                <label>Create Timestamp:</label>
                {{ identity.createTimestamp?.join(', ') ?? "n/a" }}
              </li>
              <li>
                <label>Modify Timestamp:</label>
                {{ identity.modifyTimestamp?.join(', ') ?? "n/a" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
